<template>
 <router-view></router-view>
</template>

<script>
import { canNavigateDirect } from "@/libs/acl/routeProtectionDirect";
import { getUserData } from "@/auth/utils";
export default {
beforeRouteEnter(to, from, next){
    const userData = getUserData()
    // Check if user can navigate directly to the specified route (using user's role and software engineering level)
    if(!canNavigateDirect(to, userData.arrRoles, userData.eng_soft)){
      return next({ name: "misc-not-authorized" });
    }else{
      return next()
    }
  },
};
</script>
